<template>
  <div class="header">
    <div class="header-one">
      <div class="brand">
        <div style="margin: 0 4px; min-width: 70px">{{$t('mainHeader.filter_text')}}:</div>
        <el-tag v-if="plat"
                class="plat">
          {{ $t('mainHeader.plat') + '：' + platName }}
        </el-tag>
        <el-dropdown trigger="click"
                     placement="bottom-start">
          <span class="el-dropdown-link">
            <i class="el-icon-caret-bottom"
               v-if="!this.sub_name && !isOversea && !filterObj.comment_url_id && notMultiPlat"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(val, key) in platNameMap"
                              :key="key"
                              :disabled="isOversea && filterDisabled(key)"
                              @click.native="changePlat(key)">{{ val }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-tag v-if="catName"
                class="catName tagBar">
          {{ $t('mainHeader.cat') + ': ' }}
          <span v-html="catName"></span>
        </el-tag>
        <el-popover placement="right"
                    ref="catsDrop"
                    trigger="click"
                    @show="showPop(true)"
                    @hide="showPop(false)">
          <span class="el-dropdown-link"
                slot="reference"
                style="cursor: pointer"
                v-if="!filterObj.comment_url_id">
            <i class="el-icon-caret-bottom"></i>
          </span>
          <div :style="{width: '400px', height: '500px' }"
               v-if="$store.state.user.loginInfo.cat_list">
            <CatTree ref="CatTree"
                     :showCats="showCats"
                     v-model="cats"
                     :tree-dom-id="'mainHeaderCatTreeId'"
                     :width="400"
                     :height="400"
                     :default-cats="defaultCats"
                     @on-changeDateTime="changeDateTime"></CatTree>
            <el-button style="width: 400px;"
                       type="primary"
                       @click="submitForm"
                       :disabled="cats.length == 0&&!isOversea">{{$t('search.confirm')}}</el-button>
          </div>
        </el-popover>
        <el-popover v-if="filterObj.comment_url_id"
                    placement="bottom"
                    trigger="hover">
          <el-tag closable
                  slot="reference"
                  size="mini"
                  @close="handleCommentClose"
                  class="commentName">
            电商聆听的查询条件...
          </el-tag>
          <div style="max-width: 800px;">
            <el-tag class="tagName"
                    v-if="commentParams.shop_name">
              店铺:{{commentParams.shop_name}}
            </el-tag>
            <el-tag class="tagName"
                    v-if="commentParams.brand_name">
              品牌:{{commentParams.brand_name}}
            </el-tag>
            <el-tag class="tagName"
                    v-if="commentParams.item_name">
              商品:{{commentParams.item_name}}
            </el-tag>
          </div>
        </el-popover>

        <el-tag closable
                size="mini"
                v-if="filterObj.rule"
                @close="handleRuleClose"
                class="ruleName">
          {{ filterObj.rule }}
        </el-tag>
        <el-tag closable
                size="mini"
                v-if="filterObj.brand"
                @close="handleBrandNameClose"
                class="brandName">
          {{ $t('baseInfo.brand') + '：' + filterObj.brand }}
        </el-tag>
        <el-tag closable
                size="mini"
                v-if="filterObj.shop"
                @close="handleShopNameClose"
                class="shopName">
          {{ $t('baseInfo.shop') + '：' + filterObj.shop }}
        </el-tag>
        <el-tag closable
                size="mini"
                v-if="filterObj.avg_price.length > 0 && filterObj.avg_price.filter(item => !!item).length > 0"
                @close="handleAvgPriceClose"
                class="avgPrice tagBar">
          <template v-if="!isOversea">{{ $t('baseInfo.price') + '：' + filtering(filterObj.avg_price) + $t('search.moneyUnit') }}</template>
          <template v-if="isOversea">{{ $t('baseInfo.price') + '：' + filtering(filterObj.avg_price)}}{{ moneyUnit }}</template>
        </el-tag>
        <el-tag closable
                size="mini"
                v-if="filterObj.sold.length > 0 && filterObj.sold.filter(item => !!item).length > 0"
                @close="handleSoldClose"
                class="sold tagBar">
          {{ $t('baseInfo.sold') + '：' + filtering(filterObj.sold) + '件' }}
        </el-tag>
        <el-tag closable
                size="mini"
                v-if="filterObj.sales.length > 0 && filterObj.sales.filter(item => !!item).length > 0"
                @close="handleSalesClose"
                class="sales tagBar">
          {{ $t('baseInfo.sales') + '：' + filtering(filterObj.sales) + $t('search.countUnit1') + $t('search.moneyUnit') }}
        </el-tag>
        <el-tag closable
                size="mini"
                v-if="filterObj.title"
                @close="handleTitleClose"
                class="title tagBar">
          {{ $t('baseInfo.title') + '：' + filterObj.title }}
        </el-tag>
        <el-tag closable
                size="mini"
                v-if="filterObj.item_ids"
                @close="handleItemsIdClose"
                class="item-id tagBar">
          {{ "商品ID" + '：' + (filterObj.item_ids.split('\n').length > 1 ? filterObj.item_ids.split('\n')[0] + ' ... ' : filterObj.item_ids.split('\n')[0]) }}
        </el-tag>
        <el-tag closable
                size="mini"
                v-if="filterObj.filing_name"
                @close="handleFilingNameClose"
                class="filing_name tagBar">
          {{ '备案产品名称' + '：' + filterObj.filing_name }}
        </el-tag>
        <el-tag closable
                size="mini"
                v-if="hasOnlineTime"
                @close="handleOnlineTimeClose"
                class="online_time tagBar">
          <template v-if="filterObj.online_time[0] && filterObj.online_time[1]">
            {{ $t('search.shelf_time') + ': ' + filterObj.online_time.join(' ~ ')}}
          </template>
          <template v-else-if="filterObj.online_time[0] && !filterObj.online_time[1]">
            {{ $t('search.shelf_time') + ': ' + filterObj.online_time[0] + '之后'}}
          </template>
          <template v-else-if="!filterObj.online_time[0] && filterObj.online_time[1]">
            {{ $t('search.shelf_time') + ': ' + filterObj.online_time[1] + '之前'}}
          </template>
        </el-tag>
        <el-tag closable
                size="mini"
                v-if="hasFilingDate"
                @close="handleFilingDateClose"
                class="filing_date tagBar">
          <template v-if="filterObj.filing_date[0] && filterObj.filing_date[1]">
            {{ $t('search.filing_date') + ': ' + filterObj.filing_date.join(' ~ ')}}
          </template>
          <template v-else-if="filterObj.filing_date[0] && !filterObj.filing_date[1]">
            {{ $t('search.filing_date') + ': ' + filterObj.filing_date[0] + '之后'}}
          </template>
          <template v-else-if="!filterObj.filing_date[0] && filterObj.filing_date[1]">
            {{ $t('search.filing_date') + ': ' + filterObj.filing_date[1] + '之前'}}
          </template>
        </el-tag>
        <template v-if="filterObj.attr_filters && filterObj.attr_filters.length > 0">
          <el-tooltip class="item"
                      effect="dark"
                      v-for="item in filterObj.attr_filters"
                      :key="item.id"
                      :content="item.name_tip"
                      :disabled="!item.name_tip"
                      placement="bottom-start">
            <el-tag closable
                    size="mini"
                    @close="handleAttrClose(item.id)"
                    class="attrName tagBar">
              <span v-html="computedFilterLabel(item)"></span>
            </el-tag>
          </el-tooltip>

        </template>
        <div class="myAtention"
             @click="addAtention">
          <i v-show="!isMarked"
             class="iconfont icon-heart-fill"
             style="color: #7d8a9b; margin-left: 12px; vertical-align: middle"></i>
          <i v-show="isMarked"
             class="iconfont icon-heart-fill"
             style="color: #ff1100; margin-left: 12px; vertical-align: middle"></i>
          <span class="headText"
                v-show="!isMarked">
            <span style="vertical-align: middle; position: relative; top: 0px">＋</span>
            <span style="vertical-align: middle">{{$t('mainHeader.follow')}}</span>
          </span>
          <span class="headText"
                v-show="isMarked">
            <span style="vertical-align: middle; position: relative; top: 0px">＋</span>
            <span style="vertical-align: middle">{{$t('mainHeader.followed')}}</span>
          </span>
        </div>
        <!-- <el-button class="intro-btn"
                   v-if="!isOversea&&!$store.state.user.loginInfo.is_partner"
                   @click="btnClick">
          <el-tooltip placement="left-end">
            <div slot="content">
              <p>{{$t('mainHead.guide_tooltip')}}</p>
            </div>
            <i class="el-icon-question"></i>
          </el-tooltip>
          &nbsp;{{$t('mainHead.guide')}}
        </el-button> -->
      </div>
      <div class="time-pick">
        <div style="margin: 0 8px"
             v-if="isShowCoupon && isShowUserCoupon">
          <el-select v-model="version"
                     placeholder="请选择"
                     size="mini"
                     style="width: 140px">
            <el-tooltip class="item"
                        effect="dark"
                        :content="item.date"
                        :disabled="!item.disabled"
                        placement="right-start"
                        v-for="item in couponList"
                        :key="item.value">
              <el-option :value="item.value"
                         :label="item.name"
                         :disabled="item.disabled"></el-option>
            </el-tooltip>
          </el-select>
        </div>
        <div style="margin: 0 8px"
             v-if="isShowJdVersion && loginInfo.username && loginInfo.username.endsWith('@moojing.com')">
          <el-select v-model="jdVersion"
                     @change="jdVersionChange"
                     placeholder="请选择"
                     size="mini"
                     style="width: 140px">
            <el-option v-for="item in jdVersionList"
                       :key="item.value"
                       :value="item.value"
                       :label="item.name"></el-option>
          </el-select>
        </div>
        <div style="margin: 0 8px"
             v-if="showDouyinVersion">
          <el-select v-model="douyinVersion"
                     @change="douyinVersionChange"
                     placeholder="请选择"
                     size="mini"
                     style="width: 140px">
            <el-option v-for="item in douyinVersionList"
                       :key="item.value"
                       :value="item.value"
                       :label="item.name"></el-option>
          </el-select>
        </div>
        <div class="timePick">
          <MonthPickerCpn v-model="dateTime"
                          @change="dateChange"
                          :max-date="$store.state.user.deadLineEndMonth"
                          :min-date="$store.state.user.deadLineStartMonth" />
        </div>
        <div style="margin: 0 8px">
          <el-button size="mini"
                     type="primary"
                     @click="paramsChange">{{$t('mainHeader.confirm')}}</el-button>
        </div>
        <div class="filterIcon"
             :class="{ activeFilter: isShowFilter }"
             @click="showFilter">
          <i class="iconfont icon-filter"></i>
          <span style="margin-left: 5px">{{ isShowFilter ? $t('mainHeader.hide') : $t('mainHeader.expand') }}</span>
        </div>
      </div>
    </div>

    <div class="header-two">
      <!-- <div style="width:100%"> -->
      <el-tabs v-model="editableTabsValue"
               type="card"
               @tab-click="handleTabClick"
               :before-leave="(activeName) => {
                if(activeName == 'GeneratePPT'){
                  return false
                }else if(isOversea && tabCats.length == 0){
                  return false
                }else{
                  return true
                }
               }">
        <el-tab-pane :key="item.name"
                     v-for="item in editableTabs"
                     :disabled="item.id == 'consumer_insight' && !notMultiPlat "
                     :name="item.name">
          <span slot="label">
            <span class="custom-star red"
                  v-if="item.name.indexOf('CustomTable') > -1 && item.origin_user"></span>
            <span class="custom-star"
                  v-if="item.name.indexOf('CustomTable') > -1 && !item.origin_user"></span>
            <el-popover v-if='typeof item.surplus_count == "number"'
                        placement="bottom"
                        width="200"
                        trigger="hover">
              <p v-if="item.surplus_count != 0">{{$t('mainHeader.consumer_pop_1')}}</p>
              <p v-else>{{$t('mainHeader.consumer_pop_2')}}</p>
              <span slot="reference"
                    style="float: left;
                        display: inline-block;
                        border-radius: 50%;
                        width: 25px;
                        height: 25px;
                        background-color: red;
                        color: white;
                        margin-right: 10px;
                        margin-top: 10px;
                        font-size: 10px;
                        text-align: center;
                        line-height: 25px;">{{item.surplus_count}}</span>
            </el-popover>
            <div v-if="!isOversea && item.id == 'consumer_insight' && plat.indexOf('tm') > -1 && consumerShow"
                 class="notification">
              <el-tooltip class="item"
                          content="跳转后的评论数据仅有天猫平台没有淘宝平台。"
                          effect="light"
                          placement="bottom">
                <span>{{ item.title }}</span>
              </el-tooltip>
              <span class="note-dot"></span>
            </div>
            <div v-else-if="isOversea && tabCats.length == 0 && item.id != 'catlist'">
              <el-tooltip class="item"
                          :content="$t('mainHead.only_cat_tip')"
                          effect="light"
                          placement="bottom">
                <span>{{ item.title }}</span>
              </el-tooltip>
            </div>
            <span v-else>{{ item.title }}</span>
          </span>
        </el-tab-pane>
      </el-tabs>
      <!-- </div> -->
    </div>
    <div>
      <GeneratePPT ref="GeneratePPT" />
    </div>
  </div>
</template>

<script>
import {
  getParams,
  getUid,
  putMarked,
  getMarked,
  deleteMarked,
} from '@/api/public'
import {
  parseTime,
  build_ts,
  getObjFromUrl,
  getMoneySymbol,
  isMultiPlat,
} from '@/utils'
import { timeRnage } from '@/mixins/mixins.js'
import {
  tbOptions,
  tmallOptions,
  jdOptions,
  amazonOptions,
  shopeeOptions,
  lazadaOptions,
  douyinOptions,
  tiktokOptions,
  ozonOptions,
} from './searchCpn/platOptions'
import MonthPickerCpn from '../components/monthPickerCpn/MonthPickerCpn.vue'
import CatTree from './searchCpn/CatTree.vue'
import { translate } from '@/components/I18n'
import { DateTime } from 'luxon'
import { apiKey } from '../common/params/commonParams.js'
import GeneratePPT from '../views/Detail/components/GeneratePPT.vue'
//import { getLastMonth } from '@/utils'
const defaultTabs = [
  {
    title: translate('mainHeader.tabs_plat'),
    name: 'Plat',
    id: 'plat',
  },
  {
    title: translate('mainHeader.tabs_summary'),
    name: 'Summary',
    id: 'summary',
  },
  {
    title: translate('mainHeader.tabs_price_dis'),
    name: 'PriceDis',
    id: 'price_dis',
  },
  {
    title: translate('mainHeader.tabs_catlist'),
    name: 'Catlist',
    id: 'catlist',
  },
  {
    title: translate('mainHeader.tabs_brandlist'),
    name: 'Brandlist',
    id: 'brandlist',
  },
  {
    title: translate('mainHeader.tabs_brand_price_dis'),
    name: 'BrandPriceDis',
    id: 'brand_price_dis',
  },
  {
    title: translate('mainHeader.tabs_shopslist'),
    name: 'Shopslist',
    id: 'shopslist',
  },
  {
    title: translate('mainHeader.tabs_itemslist'),
    name: 'Itemslist',
    id: 'itemslist',
  },
  {
    title: translate('mainHeader.tabs_attrlist'),
    name: 'Attrlist',
    id: 'attrlist',
  },
  {
    title: translate('mainHeader.tabs_custom_analyze'),
    name: 'customAnalyze',
    id: 'custom_analyze',
  },
  {
    title: translate('mainHeader.tabs_pivot_analyze'),
    name: 'PivotAnalyze',
    id: 'pivot_analyze',
  },
  {
    title: 'AI简报',
    name: 'GeneratePPT',
    id: 'gen_ppt',
  },
  {
    title: translate('mainHeader.tabs_consumer'),
    name: 'consumerInsight',
    id: 'consumer_insight',
  },
]
const filingTabs = [
  {
    title: translate('mainHeader.tabs_filing_product_analyze'),
    name: 'filing_product_analyze',
    id: 'filing_product_analyze',
  },
  {
    title: translate('mainHeader.tabs_filing_effect_analyze'),
    name: 'filing_effect_analyze',
    id: 'filing_effect_analyze',
  },
  {
    title: translate('mainHeader.tabs_filing_ingredient_analyze'),
    name: 'filing_ingredient_analyze',
    id: 'filing_ingredient_analyze',
  },
  {
    title: translate('mainHeader.tabs_filing_production_company_analyze'),
    name: 'filing_production_company_analyze',
    id: 'filing_production_company_analyze',
  },
]
export default {
  mixins: [timeRnage],
  components: {
    CatTree,
    MonthPickerCpn,
    GeneratePPT,
  },
  data() {
    return {
      dateTime: [
        this.$store.state.user.startMonth,
        this.$store.state.user.endMonth,
      ],
      isOversea: this.$store.state.user.isOversea,
      platTimeKey: '',
      platTimeName: this.$store.state.user.isOversea ? 'amazon_us' : 'taobao',
      platList: [],
      platNameMap: {},
      couponList: [
        { name: this.$t('mainHeader.no_discount'), value: '' },
        {
          name: this.$t('mainHeader.discount_v1'),
          value: 'cp',
          date: '2020年1月起',
        },
        // {
        //   name: this.$t('mainHeader.full_discount'),
        //   value: 'min',
        //   date: '2020年1月起',
        // },
        {
          name: this.$t('mainHeader.full_discount_v2'),
          value: 'min_v2',
          date: '2020年11月起',
        },
      ],
      couponListSku: [
        { name: this.$t('mainHeader.no_discount'), value: '' },
        {
          name: this.$t('mainHeader.discount_v1'),
          value: 'cp',
          date: '2020年1月起',
        },
        // {
        //   name: this.$t('mainHeader.full_discount'),
        //   value: 'min',
        //   date: '2020年1月起',
        // },
        {
          name: this.$t('mainHeader.full_discount_v2'),
          value: 'min_v2',
          date: '2020年11月起',
        },
      ],
      couponLists: [
        { name: this.$t('mainHeader.no_discount'), value: '' },
        {
          name: this.$t('mainHeader.discount_v1'),
          value: 'cp',
          date: '2020年1月起',
        },
        // {
        //   name: this.$t('mainHeader.full_discount'),
        //   value: 'min',
        //   date: '2020年1月起',
        // },
        {
          name: this.$t('mainHeader.full_discount_v2'),
          value: 'min_v2',
          date: '2020年11月起',
        },
      ],
      version: localStorage.getItem('version')
        ? localStorage.getItem('version')
        : this.$store.state.user.version,
      jdVersion: '',
      jdVersionList: [],
      douyinVersion: '',
      douyinVersionList: [],
      verMap: [],
      isShowCoupon: false,
      isShowJdVersion: false,
      isShowUserCoupon: true,
      plat: 'taobao',
      platName: '',
      sub_name: '',
      userVersionSelected: this.$store.state.user.loginInfo
        ? this.$store.state.user.loginInfo.version_selected || []
        : [],
      isMoojingUser: false,
      parseTime: parseTime,
      loading: false,
      loading_save: false,
      tabs: _.cloneDeep(defaultTabs),
      editableTabs: [],
      urlId: this.$store.state.user.urlId,
      param_val: getObjFromUrl(window.location.href),
      isShowFilter: false,
      showCats: 0,
      catName: '',
      cats: [],
      defaultCats: [],
      cid: {},
      showCatTree: false,
      form: {
        //用来提交的参数
        attr_vague: true,
        attrv_and: [],
        attrv_or: [],
        avg_price: ['', ''],
        brand: '',
        brand_id: '',
        cats: [],
        cid: {},
        keys: [],
        plat: '',
        sales: ['', ''],
        shop: '',
        shop_id: '',
        sub_name: '',
        sold: ['', ''],
        time: [],
        title: '',
        top: '1',
        topAttrList: [],
        rule_id: '',
        rule_group_id: '',
        title_relation: 'and',
      },
      filterObj: {
        //用来展示的参数
        brand: '',
        brand_id: '',
        shop: '',
        shop_id: '',
        attr_filters: [],
        startMonth: this.$store.state.user.startMonth,
        endMonth: this.$store.state.user.endMonth,
        avg_price: ['', ''],
        sold: ['', ''],
        sales: ['', ''],
        title: '',
        sub_name: '',
        attr_logic: '',
        top: '',
        topAttrList: [],
        attrv_and: [],
        attrv_or: [],
        rule: '',
        rule_id: '',
        rule_group_id: '',
        title_relation: 'and',
        online_time: ['', ''],
        filing_date: ['', ''],
        comment_url_id: '',
        filing_name: '',
        item_ids: '',
      },
      errorItem: { type: 'info', text: '' },
      loading: false,
      loginInfo: this.$store.state.user.loginInfo,
      modules: '',
      ts: [],
      timeList: [],
      topAttrCustomobj: {},
      tabCats: [],
      catDateTime: [],
      isMarked: false,
      isSku_Grade: this.$store.state.user.isSkuGrade, //是否选择了sku单选框
      isSku_ActivedTab: false, //是否选择了属性tab页
      isSku_Filter: false, //是否包含了属性筛选条件(除省市区县地理属性外)
      isSku_Custom: false, // 是否为自定义分析,且包含sku名搜索
      module_info: [],
      isFilingCat: false,
      commentParams: {},
      consumerShow: window.location.host.includes('mktindex.com'),
    }
  },
  computed: {
    notMultiPlat() {
      if (this.isOversea) {
        return true
      } else {
        return !(isMultiPlat(this.plat) && this.sub_name != 'custom_102')
      }
    },
    moneyUnit() {
      return this.$store.state.user.moneySymbol
    },
    languageMode() {
      return this.$store.state.user.languageMode
    },
    fenxiLanguageMode() {
      return this.$store.state.user.fenxiLanguageMode
    },
    editableTabsValue: {
      get() {
        if (this.$store.state.user.ActivedTab) {
          if (!this.isFilingCat) {
            if (
              this.$store.state.user.ActivedTab == 'filing_product_analyze' ||
              this.$store.state.user.ActivedTab == 'filing_effect_analyze' ||
              this.$store.state.user.ActivedTab ==
                'filing_ingredient_analyze' ||
              this.$store.state.user.ActivedTab ==
                'filing_production_company_analyze'
            ) {
              return 'Summary'
            }
          }
          if (
            this.multiPlatJudgment(this.plat) &&
            !this.isOversea &&
            this.form.sub_name != 'custom_102' &&
            this.$store.state.user.ActivedTab == 'Attrlist'
          ) {
            return 'Plat'
          }
          if (
            !this.multiPlatJudgment(this.plat) &&
            this.$store.state.user.ActivedTab == 'Plat'
          ) {
            return 'Summary'
          }
          return this.$store.state.user.ActivedTab
        } else {
          return 'Summary'
        }
      },
      set(val) {
        this.$store.state.user.ActivedTab = val
      },
    },
    tabParam() {
      let obj = {
        cats: this.tabCats,
        loginInfo: this.loginInfo,
        end: this.$route.query.end,
      }
      return obj
    },
    formParam() {
      let self = this
      this.form.cats = this.cats
      this.form.cid = this.cid
      this.form.sub_name = this.sub_name
      this.form.time = [this.dateTime]
      let topAttrListParams = JSON.parse(sessionStorage.getItem('topAttrList'))
      this.form.topAttrList =
        this.filterObj.topAttrList.length > 0
          ? this.filterObj.topAttrList
          : topAttrListParams || []
      let topAttrCustomobj = {}
      _(this.form.topAttrList).forEach(function (value) {
        topAttrCustomobj[value[1]] = value[0]
        if ((value[3] || value[2]) && self.isOversea) {
          topAttrCustomobj[value[3] || value[2]] = value[0]
        }
      })
      if (this.filterObj.attr_filters) {
        let arr = this.filterObj.attr_filters.map(function (item) {
          var obj = {}
          if (item.name) {
            if (item.popular_attr_prompt == 'checkbox') {
              obj.key = item.name
              obj.value = item.value
              obj.range = item.range ? item.range : null
              obj.popular_attr_prompt = item.popular_attr_prompt
              //   obj.attr_type = self.filterObj.topAttrCustomobj[item.name]
              obj.attr_type = item.attr_type
            } else {
              if (item.name == '备案产品名称') {
                obj['备案编号'] = item['备案编号']
              } else {
                obj[item.name] = item.value
              }
              //   obj['attr_type'] = self.filterObj.topAttrCustomobj[item.name]
              obj['attr_type'] = item.attr_type
            }
          } else {
            obj = null
          }
          return obj
        })
        if (this.filterObj.attr_logic == 'and') {
          this.form.attrv_and = arr
          this.form.attrv_or = []
        } else if (this.filterObj.attr_logic == 'or') {
          this.form.attrv_and = []
          this.form.attrv_or = arr
        }
      }
      if (this.filterObj.attrv_and.length > 0) {
        this.form.attrv_and = this.filterObj.attrv_and
        this.form.attrv_or = []
      }
      if (this.filterObj.attrv_or.length > 0) {
        this.form.attrv_or = this.filterObj.attrv_or
        this.form.attrv_and = []
      }
      this.form.avg_price = this.filterObj.avg_price
      this.form.brand = this.filterObj.brand
      this.form.brand_id = this.filterObj.brand_id
      this.form.sales = this.filterObj.sales
      this.form.shop = this.filterObj.shop
      this.form.shop_id = this.filterObj.shop_id
      this.form.sold = this.filterObj.sold
      this.form.title = this.filterObj.title
      this.form.top = this.filterObj.top
      this.form.title_relation = this.filterObj.title_relation
      let filterParams = {
        attr_vague: this.form.attr_vague,
        attrv_and: this.form.attrv_and,
        attrv_or: this.form.attrv_or,
        avg_price: this.form.avg_price,
        brand: this.form.brand,
        brand_id: this.form.brand_id,
        cats: this.form.cats,
        cid: this.form.cid,
        keys: this.form.keys,
        plat: this.plat,
        sales: this.form.sales,
        shop: this.form.shop,
        shop_id: this.form.shop_id,
        sold: this.form.sold,
        sub_name: this.form.sub_name,
        time: this.form.time,
        title: this.form.title,
        top: this.form.top,
        topAttrList: this.form.topAttrList,
        rule_id: this.form.rule_id,
        rule_group_id: this.form.rule_group_id,
        title_relation: this.form.title_relation,
        online_time: this.filterObj.online_time,
        filing_date: this.filterObj.filing_date,
        filing_name: this.filterObj.filing_name,
        item_ids: this.filterObj.item_ids,
        comment_url_id: this.filterObj.comment_url_id,
      }
      if (
        this.form.cats.length == 0 &&
        this.$store.state.user.filterParams.cats
      ) {
        filterParams.cats = this.$store.state.user.filterParams.cats
        let catInfo = filterParams.cats[0]
        if (catInfo.custom) {
          filterParams.cid = {
            custom: [catInfo._id],
          }
        } else {
          filterParams.cid = {
            not_custom: [catInfo.id],
          }
        }
      }
      if (this.filterObj.rule_id && this.filterObj.rule_group_id) {
        filterParams.rule_id = this.filterObj.rule_id
        filterParams.rule_group_id = this.filterObj.rule_group_id
      }
      if (this.filterObj.title_trans) {
        filterParams.title_trans = this.filterObj.title_trans
      }
      return filterParams
    },
    isSku() {
      if (
        this.isSku_Grade ||
        this.isSku_ActivedTab ||
        this.isSku_Filter ||
        this.isSku_Custom
      ) {
        return true
      } else {
        return false
      }
    },
    hasOnlineTime() {
      const onlineTime = this.filterObj.online_time
      if (!onlineTime || !Array.isArray(onlineTime)) {
        return false
      }
      if (onlineTime.length > 0) {
        if (onlineTime[0] || onlineTime[1]) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    hasFilingDate() {
      const FilingDate = this.filterObj.filing_date
      if (!FilingDate || !Array.isArray(FilingDate)) {
        return false
      }
      if (FilingDate.length > 0) {
        if (FilingDate[0] || FilingDate[1]) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    innerHeight() {
      return window.innerHeight
    },
    showDouyinVersion() {
      if (
        this.$store.state.user.loginInfo.username &&
        !this.$store.state.user.loginInfo.username.endsWith('@moojing.com')
      ) {
        return false
      }
      if (this.plat.split(',').length > 1) {
        return false
      }
      if (this.plat.indexOf('douyin') > -1) {
        if (this.sub_name == 'custom_102') {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
  },
  async created() {
    this.getUrlId = _.debounce(this.getUrlIds, 1000)
    this.isTarget()
    await this.getParams()
    this.getPlatList()
    this.buildVerList()
  },
  mounted() {
    // if (apiKey[this.$store.state.user.ActivedTab]) {
    //   let botParam = _.cloneDeep(this.$store.state.user.chatParams)
    //   botParam.tab = apiKey[this.$store.state.user.ActivedTab].tab
    //   botParam.page_info = {}
    //   this.$store.dispatch('user/changeChatParams', botParam)
    // }
    this.$eventBus.$on('changeCatById', this.changeCatById)
    // jd_version
    this.$eventBus.$on('jdVersionListChange', this.changeJdVersionList)
    this.$eventBus.$on('douyinVersionListChange', this.changeDouyinVersionList)
    const jdList = JSON.parse(localStorage.getItem('jdVersionList'))
    const douyinList = JSON.parse(localStorage.getItem('douyinVersionList'))
    if (jdList && jdList.length > 0) {
      this.jdVersionList = jdList.map((item) => ({ name: item, value: item }))
      this.jdVersion = JSON.parse(localStorage.getItem('jdVersion'))
    } else {
      this.jdVersionList = []
      this.jdVersion = ''
    }
    if (douyinList && douyinList.length > 0) {
      this.douyinVersionList = douyinList.map((item) => ({
        name: item,
        value: item,
      }))
      this.douyinVersion = JSON.parse(localStorage.getItem('douyinVersion'))
    } else {
      this.douyinVersionList = []
      this.douyinVersion = ''
    }

    // this.$eventBus.$on('introEventComplete', (module) => {
    //   const arr = [
    //     { value: 'summary', tab_name: 'Summary' },
    //     { value: 'priceDis', tab_name: 'PriceDis' },
    //     { value: 'catList', tab_name: 'Catlist' },
    //     { value: 'brandList', tab_name: 'Brandlist' },
    //     { value: 'brandPriceDis', tab_name: 'BrandPriceDis' },
    //     { value: 'shopsList', tab_name: 'Shopslist' },
    //     { value: 'itemsList', tab_name: 'Itemslist' },
    //     { value: 'attrList', tab_name: 'Attrlist' },
    //     { value: 'customAnalyze', tab_name: 'customAnalyze' },
    //     { value: 'pivotAnalyze', tab_name: 'PivotAnalyze' },
    //   ]
    //   const index = arr.findIndex((item) => item.value == module)
    //   for (let i = index + 1; i < arr.length; i++) {
    //     const arr_item = arr[i]
    //     if (
    //       this.editableTabs.findIndex(
    //         (item) => item.name == arr_item.tab_name
    //       ) > 0
    //     ) {
    //       this.$store.dispatch('user/changeActivedTab', arr_item.tab_name)
    //       localStorage.setItem('passActivedTab', arr_item.tab_name)
    //       break
    //     } else {
    //       continue
    //     }
    //   }
    // })
  },
  beforeDestroy() {
    this.$eventBus.$off('changeCatById', this.changeCatById)
    this.$eventBus.$off('jdVersionListChange', this.changeJdVersionList)
    this.$eventBus.$off('douyinVersionListChange', this.changeDouyinVersionList)
    // 组件销毁前, 其店铺筛选条件应该重置
    this.$store.dispatch('user/changeShopName', '')
    this.$store.dispatch('user/changeShopId', '')
    // this.$eventBus.$off('introEventComplete')
  },
  methods: {
    computedFilterLabel(item) {
      let resultLabel =
        (item.name_show || item.name).replace('(清洗)', '') + '：' + item.value
      if (item.range) {
        if (item.value) {
          resultLabel += ' or '
        }
        resultLabel += item.range.value_range.join('~')
        resultLabel += item.range.unit
      }
      return resultLabel
    },
    isTarget() {
      const { is_target, tab, isSku, start, end, type } = getObjFromUrl(
        window.location.hash
      )
      if (!is_target && type !== 'mmi') {
        return
      }
      if (tab) {
        this.$store.dispatch('user/changeActivedTab', tab)
        localStorage.setItem('passActivedTab', tab)
      }
    },
    // btnClick() {
    //   var tabvalue = this.editableTabsValue
    //   if (this.$store.state.user.isOversea == true) {
    //     return
    //   }
    //   switch (tabvalue) {
    //     case 'Summary':
    //       this.$eventBus.$emit('loadIntroJs', 'Summary')
    //       break
    //     case 'PriceDis':
    //       this.$eventBus.$emit('loadIntroJs', 'PriceDis')
    //       break
    //     case 'Catlist':
    //       this.$eventBus.$emit('loadIntroJs', 'Catlist')
    //       break
    //     case 'Brandlist':
    //       this.$eventBus.$emit('loadIntroJs', 'Brandlist')
    //       break
    //     case 'BrandPriceDis':
    //       this.$eventBus.$emit('loadIntroJs', 'BrandPriceDis')
    //       break
    //     case 'Shopslist':
    //       this.$eventBus.$emit('loadIntroJs', 'Shopslist')
    //       break
    //     case 'Itemslist':
    //       this.$eventBus.$emit('loadIntroJs', 'Itemslist')
    //       break
    //     case 'Attrlist':
    //       this.$eventBus.$emit('loadIntroJs', 'Attrlist')
    //       break
    //     case 'customAnalyze':
    //       this.$eventBus.$emit('loadIntroJs', 'customAnalyze')
    //       break
    //     case 'PivotAnalyze':
    //       this.$eventBus.$emit('loadIntroJs', 'PivotAnalyze')
    //       break
    //   }
    // },
    changeJdVersionList(val) {
      this.jdVersionList = val.map((item) => ({ name: item, value: item }))
      this.jdVersion = JSON.parse(localStorage.getItem('jdVersion'))
      this.$eventBus.$emit('jdVersionChange', this.jdVersion)
    },
    changeDouyinVersionList(val) {
      this.douyinVersionList = val.map((item) => ({ name: item, value: item }))
      this.douyinVersion = JSON.parse(localStorage.getItem('douyinVersion'))
      this.$eventBus.$emit('douyinVersionChange', this.douyinVersion)
    },
    changeDateTime(dateTime) {
      this.catDateTime = dateTime
      this.getTimeList()
      setTimeout(() => {
        this.catDateTimeChange(this.catDateTime)
      }, 0)
    },
    catDateTimeChange(dateTime) {
      let month = [
        this.$store.state.user.deadLineStartMonth,
        this.$store.state.user.deadLineEndMonth,
      ]
      if (dateTime.length > 0) {
        let deadLine = []
        if (dateTime[0] >= month[0]) {
          if (dateTime[0] > month[1]) {
            deadLine = month
            return
          }
          deadLine[0] = dateTime[0]
        } else {
          deadLine[0] = month[0]
        }
        if (dateTime[1] >= month[1]) {
          deadLine[1] = month[1]
        } else {
          deadLine[1] = dateTime[1]
        }
        this.$store.dispatch('user/changeDeadLineStartMonth', deadLine[0])
        this.$store.dispatch('user/changeDeadLineEndMonth', deadLine[1])
        // 当选择时间超过权限时间,默认修改为权限范围时间内
        if (
          DateTime.fromISO(this.dateTime[0]).ts <
          DateTime.fromISO(deadLine[0]).ts
        ) {
          this.$store.dispatch('user/changeStartMonth', deadLine[0])
          this.dateTime[0] = deadLine[0]
        }
        if (
          DateTime.fromISO(this.dateTime[0]).ts >
          DateTime.fromISO(deadLine[1]).ts
        ) {
          this.$store.dispatch('user/changeStartMonth', deadLine[1])
          this.dateTime[0] = deadLine[1]
        }
        if (
          DateTime.fromISO(this.dateTime[1]).ts >
          DateTime.fromISO(deadLine[1]).ts
        ) {
          this.$store.dispatch('user/changeEndMonth', deadLine[1])
          this.dateTime[1] = deadLine[1]
        }
        if (
          DateTime.fromISO(this.dateTime[1]).ts <
          DateTime.fromISO(deadLine[0]).ts
        ) {
          this.$store.dispatch('user/changeEndMonth', deadLine[1])
          this.dateTime[1] = deadLine[1]
        }
        this.dateChange(this.dateTime)
        localStorage.setItem('deadLineStartMonth', deadLine[0])
        localStorage.setItem('deadLineEndMonth', deadLine[1])
      }
    },
    dateChange(val) {
      if (val) {
        this.filterObj.startMonth = val[0]
        this.filterObj.endMonth = val[1]
      }
    },
    filterDisabled(plat) {
      if (plat.indexOf('amazon') > -1) {
        if (plat == 'amazon_jp') {
          return false
        } else {
          return false
        }
        // return false
      } else if (plat.indexOf('shopee') > -1) {
        if (
          plat == 'shopee_vn' ||
          plat == 'shopee_th' ||
          plat == 'shopee_sg' ||
          plat == 'shopee_my' ||
          plat == 'shopee_ph' ||
          plat == 'shopee_id' ||
          plat == 'shopee_tw'
        ) {
          return false
        } else {
          return true
        }
      } else if (plat.indexOf('lazada') > -1) {
        if (
          plat == 'lazada_vn' ||
          plat == 'lazada_th' ||
          plat == 'lazada_sg' ||
          plat == 'lazada_my' ||
          plat == 'lazada_ph' ||
          plat == 'lazada_id'
        ) {
          return false
        } else {
          return true
        }
      } else if (plat.indexOf('tiktok') > -1) {
        if (
          plat == 'tiktok_gb' ||
          plat == 'tiktok_id' ||
          plat == 'tiktok_my' ||
          plat == 'tiktok_ph' ||
          plat == 'tiktok_sg' ||
          plat == 'tiktok_us' ||
          plat == 'tiktok_vn' ||
          plat == 'tiktok_th'
        ) {
          return false
        } else {
          return true
        }
      } else if (plat.indexOf('ozon') > -1) {
        if (plat == 'ozon_ru') {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    filtering(arr) {
      if (arr.length > 0 && arr[0] == '' && arr[1] == '') {
        return []
      } else if (arr[0] == '' && arr[1] != '') {
        let arrs = ['<', arr[1]]
        return arrs.join('=')
      } else if (arr[1] == '' && arr[0] != '') {
        let arrs = ['>', arr[0]]
        return arrs.join('=')
      } else {
        return arr.join('~')
      }
    },
    changeCatById(catId) {
      const catInfo = this.$refs.CatTree.getCatInfoById(catId)
      this.cats = [catInfo]
      if (catInfo.custom) {
        this.cid = {
          custom: [catInfo._id],
        }
      } else {
        this.cid = {
          not_custom: [catInfo.id],
        }
      }
      this.getUrlIds().then((res) => {
        this.$store.dispatch('user/changeActivedTab', 'Summary')
        localStorage.setItem('passActivedTab', 'Summary')
      })
    },
    showPop(val) {
      if (val) {
        this.$refs.CatTree.loadCats()
        this.$refs.CatTree.debounceCheckCats()
      }
    },
    setTab() {
      var modulesShouldBeDisplayed = this.setModuleDisplay()
      let tabsData = _.filter(this.tabs, function (tab) {
        return _.includes(modulesShouldBeDisplayed, tab.id)
      })
      for (let i = 0; i < tabsData.length; i++) {
        this.editableTabs.push(tabsData[i])
      }
      const routeQuery = getObjFromUrl(window.location.hash)
      const endTs = DateTime.fromISO(routeQuery.end).ts
      const attrMinTS = DateTime.fromISO('2020-01').ts
      if (endTs < attrMinTS) {
        this.editableTabs = this.editableTabs.filter((item) => {
          return item.id !== 'attrlist'
        })
      }
      if (this.filterObj.comment_url_id) {
        this.editableTabs = this.editableTabs.filter((item) => {
          return item.name !== 'Catlist'
        })
      }
      // 内部用户增加"AI简报"功能
      // if (this.loginInfo.username.endsWith('@moojing.com')) {
      //   const pivotIndex = this.editableTabs.findIndex(
      //     (item) => item.id == 'pivot_analyze'
      //   )
      //   this.editableTabs.splice(pivotIndex + 1, 0, {
      //     title: 'AI简报',
      //     id: 'generate_ppt',
      //     name: 'GeneratePPT',
      //   })
      // }
      if (this.multiPlatJudgment(this.formParam.plat) && !this.isOversea) {
        //混选展示平台分析不展示属性分析
        this.editableTabs.unshift({
          title: translate('mainHeader.tabs_plat'),
          name: 'Plat',
          id: 'plat',
        })
        if (this.form.sub_name != 'custom_102') {
          this.editableTabs = this.editableTabs.filter((item) => {
            return item.id !== 'attrlist'
          })
        }
      }
    },
    setModuleDisplay() {
      let self = this
      var catModules = setCatCtrlModule() || [
        'plat',
        'summary',
        'catlist',
        'brandlist',
        'shopslist',
        'itemslist',
        'attrlist',
      ]
      catModules = _.filter(catModules, function (module) {
        switch (module) {
          case 'plat':
            if (self.multiPlatJudgment(self.plat)) {
              return true
            } else {
              return false
            }
          case 'summary':
            return true
          case 'catlist':
            if (
              self.tabCats.length == 1 &&
              !JSON.parse(self.tabCats[0].is_parent)
            ) {
              return false
            }
            return true
          case 'brandlist':
            return true
          case 'shopslist':
            return true
          case 'itemslist':
            return true
          case 'attrlist':
            return true
          case 'price_dis':
            // 取消从用户权限去做判断
            // var showCids = self.loginInfo.price_dis['jd'].concat(
            //   self.loginInfo.price_dis['taobao']
            // )
            // var isShow = _.every(self.tabCats, function (cat) {
            //   return _.intersection(showCids, cat.cid_path).length > 0
            // })
            // if (self.tabCats[0].sub_name === 'custom_102') {
            //   isShow = true
            // }
            return true
          case 'custom_analyze':
            return true
          case 'consumer_insight':
            return true
          default:
            return true
        }
      })
      return catModules
      function setCatCtrlModule() {
        if (!self.loginInfo.module_info) {
          return false
        }
        var modules
        _.forEach(self.tabCats, function (cat) {
          var cid = cat.sub_name
          if (
            self.loginInfo.module_info &&
            self.loginInfo.module_info.cid &&
            !self.loginInfo.module_info.cid[cid]
          )
            return
          let cidModules = self.loginInfo.module_info.cid[cid].modules
          modules = modules ? _.intersection(modules, cidModules) : cidModules
        })
        modules =
          modules ||
          (self.loginInfo.module_info.cid[self.plat] &&
            self.loginInfo.module_info.cid[self.plat].modules) ||
          self.loginInfo.module_info.cid.default.modules
        modules = [...new Set(modules.concat(...self.module_info))]
        if (self.plat.indexOf('tiktok') > -1) {
          modules = modules.filter((item) => {
            return item.indexOf('brand') == -1
          })
        }
        if (self.plat.indexOf('ozon') > -1) {
          modules = modules.filter((item) => {
            return item.indexOf('attr') == -1
          })
        }
        return modules || self.loginInfo.module_info.cid.default.modules
      }
    },
    getTimeList() {
      if (this.sub_name) {
        this.platTimeKey = [this.platTimeName, this.sub_name].join('|')
      } else {
        this.platTimeKey = this.platTimeName
      }
      if (this.ts) {
        this.timeList = build_ts(
          this.ts[this.platTimeKey].end,
          this.ts[this.platTimeKey].start
        )
        this.$store.dispatch(
          'user/changeDeadLineStartMonth',
          this.ts[this.platTimeKey].start
        )
        this.$store.dispatch(
          'user/changeDeadLineEndMonth',
          this.ts[this.platTimeKey].end
        )
      }
    },
    changePlat(val) {
      this.platName = this.platNameMap[val]
      this.plat = val
      if (val.indexOf('shopee') > -1 || val.indexOf('lazada') > -1) {
        let plat = 'cache_cat_oversea_' + val
        if (window[plat]) {
          let cid = window[plat].result.bigCategory[0]
          let childrenList = window[plat].result.childrenList
          this.cats = [
            {
              cid_path: [cid],
              custom: false,
              fix_id: cid,
              id: cid,
              is_parent: childrenList[cid].is_parent,
              name: this.getCatName(childrenList[cid]),
              path: [this.getCatName(childrenList[cid])],
              sub_name: '',
              top_id: cid,
              _id: cid,
            },
          ]
          this.cid = {
            not_custom: [cid],
          }
        }
      }
      this.getUrlId()
    },
    getCatName({
      name,
      name_en,
      category_name,
      category_name_en,
      name_source,
    }) {
      let lang = localStorage.getItem('language')
        ? localStorage.getItem('language')
        : this.$store.state.user.isOversea
        ? 'zh'
        : 'zh'
      let languageMode = this.$store.state.user.languageMode
      let fenxiLanguageMode = this.$store.state.user.fenxiLanguageMode
      let cat_name = ''
      if (lang == 'zh') {
        cat_name = name || category_name
      } else {
        cat_name = cat_name +=
          name_en || category_name_en || name || category_name
      }
      if (languageMode) {
        if (languageMode == 'system') {
        } else if (languageMode == 'source') {
          cat_name = name_source ? name_source : cat_name
        } else {
          cat_name = `${cat_name}${name_source ? '/' + name_source : ''}`
        }
      }
      if (
        !this.$store.state.user.isOversea &&
        localStorage.getItem('language') == 'en'
      ) {
        if (fenxiLanguageMode == 'zh') {
          cat_name = category_name || name
        } else if (fenxiLanguageMode == 'en') {
          cat_name = category_name_en ? category_name_en : cat_name
        } else {
          cat_name = category_name_en
            ? (category_name || name) + '/' + category_name_en
            : category_name || name
        }
      }
      if (cat_name == 'undefined') {
        cat_name = ''
      }
      return cat_name
    },
    getModuleInfo() {
      if (this.sub_name) {
        this.modules = this.loginInfo.module_info.cid[this.sub_name].modules
      } else {
        this.modules = this.loginInfo.module_info.cid.default.modules
      }
    },
    showPlats(val) {
      const length = val.length
      const valStr = val.join()
      if (length == 1) {
        if (valStr == 'cshop' || valStr == 'tmall') {
          this.showCats = 3
        } else if (valStr == 'jd') {
          this.showCats = 4
        } else if (valStr.indexOf('jd') == 0) {
          // 以jd开头的字符串为 京东子平台
          this.showCats = 2
        } else if (
          valStr.indexOf('amazon') > -1 ||
          valStr.indexOf('lazada') > -1 ||
          valStr.indexOf('shopee') > -1 ||
          valStr.indexOf('tiktok') > -1 ||
          valStr.indexOf('ozon') > -1
        ) {
          this.showCats = valStr
        } else if (valStr.indexOf('douyin') > -1) {
          this.showCats = 8
        } else {
          // 淘宝子平台
          this.showCats = 1
        }
      } else if (length == 2) {
        if (this.isOversea) {
          this.showCats = 6
        } else {
          if (valStr == 'cshop,tmall' || valStr == 'tmall,cshop') {
            this.showCats = 3
          } else if (valStr == 'jd,tmall' || valStr == 'tmall,jd') {
            this.showCats = 11
          } else if (valStr == 'jd,cshop' || valStr == 'cshop,jd') {
            this.showCats = 11
          } else {
            this.showCats = 1
          }
        }
      } else if (length == 3) {
        if (this.isOversea) {
          this.showCats = 6
        } else {
          if (
            val.indexOf('cshop') > -1 &&
            val.indexOf('tmall') > -1 &&
            val.indexOf('jd') > -1
          ) {
            this.showCats = 11
          } else {
            this.showCats = 1
          }
        }
      } else if (length == 4) {
        if (this.isOversea) {
          this.showCats = 6
        } else {
          this.showCats = 1
        }
      }
    },
    submitForm() {
      if (_.isEqual(this.cid, this.form.cid)) {
        this.$refs.catsDrop.doClose()
        return false
      }
      this.tabCats = this.cats
      this.form.cats = this.cats
      this.form.cid = this.cid
      this.form.sub_name = this.sub_name
      this.form.attrv_and = []
      this.form.attrv_or = []
      this.form.topAttrList = []
      this.filterObj.attr_filters = []
      this.filterObj.attrv_and = []
      this.filterObj.attrv_or = []
      this.filterObj.topAttrList = []
      if (!this.isFilingCat) {
        this.filterObj.filing_date = ['', '']
        this.filterObj.filing_name = ''
      }
      this.$refs.catsDrop.doClose()
      this.$store.dispatch('user/changeCats', this.cats)
      this.getUrlId()
      this.catDateTimeChange(this.catDateTime)
    },
    timeCouponChange() {
      if (new Date(this.dateTime[0]) < new Date('2020-01')) {
        let index = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'cp'
        })
        let index1 = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'min'
        })
        let index2 = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'min_v2'
        })
        if (this.couponList.length > 0) {
          if (index != -1) {
            this.$set(this.couponList[index], 'disabled', true)
          }
          if (index1 != -1) {
            this.$set(this.couponList[index1], 'disabled', true)
          }
          if (index2 != -1) {
            this.$set(this.couponList[index2], 'disabled', true)
          }
        }
      } else if (
        new Date('2020-01') <= new Date(this.dateTime[0]) &&
        new Date(this.dateTime[0]) < new Date('2020-11')
      ) {
        let index = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'cp'
        })
        let index1 = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'min'
        })
        let index2 = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'min_v2'
        })
        if (index != -1) {
          this.$set(this.couponList[index], 'disabled', false)
        }
        if (index1 != -1) {
          this.$set(this.couponList[index1], 'disabled', false)
        }
        if (index2 != -1) {
          this.$set(this.couponList[index2], 'disabled', true)
        }
      } else {
        _(this.couponList).forEach(function (value) {
          value['disabled'] = false
        })
      }
      this.$forceUpdate()
    },
    paramsChange() {
      if (this.dateTime) {
        this.filterObj.startMonth = this.dateTime[0]
        this.filterObj.endMonth = this.dateTime[1]
      }
      this.$store.dispatch('user/changeStartMonth', this.filterObj.startMonth)
      this.$store.dispatch('user/changeEndMonth', this.filterObj.endMonth)
      this.$store.dispatch('user/changeVersion', this.version)
      localStorage.setItem('version', this.version)
      this.$set(this.form.time, '0', [
        this.filterObj.startMonth,
        this.filterObj.endMonth,
      ])
      this.timeCouponChange()
      //   this.getUrlId()
      this.$router.push({
        name: 'detail',
        query: {
          url_id: this.urlId ? this.urlId : '',
          start: parseTime(this.filterObj.startMonth, '{y}-{m}'),
          end: parseTime(this.filterObj.endMonth, '{y}-{m}'),
        },
      })
    },
    setParams() {
      let filterParams = JSON.parse(sessionStorage.getItem('filterParams'))
      if (filterParams && Object.keys(filterParams).length > 0) {
        this.form.attr_vague = filterParams.attr_vague
        this.form.attrv_and = filterParams.attrv_and
        this.form.attrv_or = filterParams.attrv_or
        this.form.avg_price = filterParams.avg_price
        this.form.brand = filterParams.brand
        this.form.brand_id = filterParams.brand_id
        this.form.cats = self.cats
        this.form.cid = filterParams.cid
        this.form.keys = filterParams.keys
        this.form.plat = filterParams.plat
        this.form.sales = filterParams.sales
        this.form.shop = filterParams.shop
        this.form.shop_id = filterParams.shop_id
        this.form.sold = filterParams.sold
        this.form.time = filterParams.time
        this.form.title = filterParams.title
        this.form.top = filterParams.top
        this.form.topAttrList = filterParams.topAttrList
      }
    },
    getUrlIds() {
      let self = this
      this.isShowFilter = false
      this.$store.dispatch('user/changeShowFilter', false)
      this.$eventBus.$emit('showDetail', false)
      const params = _.cloneDeep(this.formParam)
      const filterParams = this.$store.state.user.filterParams
      if (params.plat == 'amazon_jp' || params.plat == 'amazon_de') {
        if (filterParams.title_ori_input) {
          params.title = filterParams.title_ori_input
        }
      }
      return getUid(params)
        .then((res) => {
          if (res.status == 'ok') {
            // self.$store.dispatch('user/changeActivedTab', 'Summary')
            this.$store.dispatch('user/changeplants', this.formParam.plat)
            this.$store.dispatch('user/changeSubName', this.formParam.sub_name)
            if (res.result.url_id == this.$store.state.user.urlId) {
              this.form.keys = []
              this.urlId = res.result.url_id
              this.isShowFilter = false
              this.$eventBus.$emit('showDetail', false)
              this.getParams()
            } else {
              self.$store.dispatch('user/changeUrlId', res.result.url_id)
              self.$router.push({
                name: 'detail',
                query: {
                  url_id: res.result.url_id ? res.result.url_id : '',
                  start: parseTime(
                    this.$store.state.user.startMonth,
                    '{y}-{m}'
                  ),
                  end: parseTime(this.$store.state.user.endMonth, '{y}-{m}'),
                },
              })
            }
          }
          self.loading = false
        })
        .catch((e) => {
          console.log(e)
          self.loading = false
          this.$message({
            message: e.backMsg,
            type: 'warning',
          })
        })
        .finally(() => {
          self.loading = false
        })
    },
    multiPlatJudgment(val) {
      let arr = val.split(',')
      let platArr = arr.map((item) => {
        if (
          item == 'cshop' ||
          item == 'global' ||
          item == 'tmall' ||
          item == 'tmallhk' ||
          item == 'chaoshi' ||
          item == 'tmallhk_gfzy' ||
          item == 'nochaoshi' ||
          item == 'notmallhk' ||
          item == 'tmqjd' ||
          item == 'tmzyzmd'
        ) {
          return 'taobao'
        } else if (
          item == 'jd' ||
          item == 'jd_only_self' ||
          item == 'jd_exclude_self' ||
          item == 'jd_hk' ||
          item == 'jd_home_self' ||
          item == 'jd_home_pop' ||
          item == 'jd_not_home_self' ||
          item == 'jd_not_home_pop'
        ) {
          return 'jd'
        } else if (item == 'douyin') {
          return 'douyin'
        } else {
          return item
        }
      })
      if (
        platArr.includes('taobao') &&
        (platArr.includes('jd') || platArr.includes('douyin'))
      ) {
        return true
      } else {
        if (platArr.includes('jd') && platArr.includes('douyin')) {
          return true
        } else {
          return false
        }
      }
    },
    getParams() {
      let self = this
      this.loading = true
      if (!this.urlId) {
        return
      }
      if (!this.douyinVersion) {
        this.douyinVersion = JSON.parse(localStorage.getItem('douyinVersion'))
      }
      getParams({ url_id: this.urlId, douyin_version: this.douyinVersion })
        .then((res) => {
          if (res.status == 'ok') {
            this.commentParams = res.result.comment_params

            this.plat = res.result.data.plat
            if (this.isOversea) {
              this.$store.dispatch('user/changeplants', res.result.data.plat)
            }
            if (
              res.result.data.sub_name == 'custom_103' ||
              res.result.data.sub_name == 'custom_104' ||
              res.result.data.cats.findIndex(
                (item) =>
                  item.cid_path[0] == '1801' ||
                  item.cid_path[0] == '50010788' ||
                  item.cid_path[0] == '20085' ||
                  item.cid_path[0] == '20029'
              ) > -1
            ) {
              this.isFilingCat = true
            } else {
              this.isFilingCat = false
            }
            let tabsData = _.cloneDeep(defaultTabs)
            if (!this.consumerShow) {
              tabsData = tabsData.filter((item) => {
                return item.name !== 'consumerInsight'
              })
            }
            if (!this.isOversea && res.result.data.oversea_url) {
              tabsData.push({
                title: this.$t('mainHeader.oversea_insight'),
                name: 'overseaInsight',
                id: 'oversea_insight',
              })
            }
            if (this.isFilingCat) {
              tabsData = tabsData.concat(filingTabs)
            }
            if (this.multiPlatJudgment(res.result.data.plat)) {
              //   tabsData = tabsData.filter((item) => {
              //     return !['summary'].includes(item.id)
              //   })
            } else {
              tabsData = tabsData.filter((item) => {
                return !['plat'].includes(item.id)
              })
              // 非多平台, 自动跳转到summary Tab && 考虑自定义分析编辑跳转 && 透视分析编辑跳转
            }
            this.tabs = _.cloneDeep(tabsData)
            this.module_info = res.result.module_info.map((item) => {
              const index = this.tabs.findIndex((ele) => ele.id == item.rule_id)
              if (index == -1) {
                if (res.result.data.rule && res.result.data.rule_id) {
                  if (res.result.data.rule_id == item.rule_id) {
                    this.tabs.push({
                      title: item.module_zh,
                      id: item.rule_id,
                      name:
                        'CustomTable' + '-' + item.rule_id + '-' + item.url_id,
                      origin_user: item.origin_user,
                    })
                  }
                } else {
                  this.tabs.push({
                    title: item.module_zh,
                    id: item.rule_id,
                    name:
                      'CustomTable' + '-' + item.rule_id + '-' + item.url_id,
                    origin_user: item.origin_user,
                  })
                }
              }
              if (index > -1 && typeof item.surplus_count == 'number') {
                this.tabs[index].surplus_count = item.surplus_count
              }
              return item.rule_id
            })
            if (!this.isOversea && res.result.data.oversea_url) {
              this.module_info.unshift('oversea_insight')
            }
            this.$store.dispatch('user/changeModuleInfo', this.module_info)
            const plats = this.plat.split(',')
            let platArr = []
            if (plats.length > 0) {
              let arr = plats.map((item) => {
                if (
                  item == 'cshop' ||
                  item == 'global' ||
                  item == 'tmall' ||
                  item == 'tmallhk' ||
                  item == 'chaoshi' ||
                  item == 'tmallhk_gfzy' ||
                  item == 'nochaoshi' ||
                  item == 'notmallhk' ||
                  item == 'tmqjd' ||
                  item == 'tmzyzmd'
                ) {
                  return 'taobao'
                } else if (
                  item == 'jd' ||
                  item == 'jd_only_self' ||
                  item == 'jd_exclude_self' ||
                  item == 'jd_hk' ||
                  item == 'jd_home_self' ||
                  item == 'jd_home_pop' ||
                  item == 'jd_not_home_self' ||
                  item == 'jd_not_home_pop'
                ) {
                  return 'jd'
                } else {
                  return item
                }
              })
              this.platTimeName = _.uniq(arr).join()
              platArr = _.uniq(arr)
            }
            // let type = 'cshop,tmall';
            // plats.forEach(item => {
            //   if(this.checkPlatFenxi(item) == 'jd'){
            //     type = 'jd'
            //   }
            // })
            if (platArr.indexOf('jd') == 0) {
              if (this.multiPlatJudgment(this.plat)) {
                if (platArr.indexOf('taobao') != -1) {
                  this.isShowCoupon = true
                } else {
                  this.isShowJdVersion = true
                }
                this.platNameMap = Object.assign({}, tbOptions, tmallOptions)
              } else {
                this.isShowCoupon = false
                this.isShowJdVersion = true
                this.platNameMap = jdOptions
              }
            } else if (this.plat.indexOf('amazon') == 0) {
              this.isShowCoupon = false
              this.isShowJdVersion = false
              this.platNameMap = amazonOptions
            } else if (this.plat.indexOf('shopee') == 0) {
              this.isShowCoupon = false
              this.isShowJdVersion = false
              this.platNameMap = shopeeOptions
            } else if (this.plat.indexOf('lazada') == 0) {
              this.isShowCoupon = false
              this.isShowJdVersion = false
              this.platNameMap = lazadaOptions
            } else if (this.plat.indexOf('tiktok') == 0) {
              this.isShowCoupon = false
              this.isShowJdVersion = false
              this.platNameMap = tiktokOptions
            } else if (this.plat.indexOf('ozon') == 0) {
              this.isShowCoupon = false
              this.isShowJdVersion = false
              this.platNameMap = ozonOptions
            } else if (platArr.indexOf('douyin') == 0) {
              if (this.multiPlatJudgment(this.plat)) {
                if (platArr.indexOf('taobao') != -1) {
                  this.isShowCoupon = true
                } else {
                  if (platArr.indexOf('jd') != -1) {
                    this.isShowJdVersion = true
                  }
                }
                this.platNameMap = Object.assign({}, tbOptions, tmallOptions)
              } else {
                this.isShowCoupon = false
                this.isShowJdVersion = false
                this.platNameMap = douyinOptions
              }
            } else {
              this.isShowCoupon = true
              this.isShowJdVersion = false
              this.platNameMap = Object.assign({}, tbOptions, tmallOptions)
            }
            const platMap = Object.assign(
              {},
              tbOptions,
              tmallOptions,
              jdOptions,
              amazonOptions,
              shopeeOptions,
              lazadaOptions,
              tiktokOptions,
              douyinOptions,
              ozonOptions
            )
            const platNames = plats.map((item) => platMap[item])
            this.platName = platNames.join('+')
            this.showPlats(plats)
            this.defaultCats = res.result.data.cats
            this.tabCats = res.result.data.cats
            this.$store.dispatch('user/changeCats', res.result.data.cats)

            _.forEach(this.tabCats, function (cat) {
              if (typeof cat.custom == 'string') {
                cat['custom'] = cat.custom === 'true' ? true : false
              }
              if (cat.custom) {
                if (!cat.id || !_.includes(cat.id, cat.sub_name)) {
                  if (cat._id == cat.sub_name) {
                    cat['id'] = cat.sub_name
                  } else {
                    cat['id'] = cat.sub_name + '_' + cat._id
                  }
                }
              } else {
                if (!cat.id) {
                  cat['id'] = cat._id
                }
              }
              if (cat['fix_id']) {
                cat['fix_id'] = cat.fix_id.replace('undefined', '')
              }
              var catChdList = window[
                'cache_cat_' + self.checkPlatFenxi(cat.platform)
              ]
                ? window['cache_cat_' + self.checkPlatFenxi(cat.platform)]
                    .result.childrenList
                : {}
              if (catChdList[cat.id]) {
                cat['is_parent'] = catChdList[cat.id].is_parent
              }
            })
            this.editableTabs = []
            let interval = setInterval(() => {
              if (
                this.$refs.CatTree &&
                this.$refs.CatTree.catTreeObj &&
                this.$refs.CatTree.catTreeObj.getNodeByParam &&
                this.defaultCats.length > 0
              ) {
                this.$store.dispatch(
                  'user/changeCatTreeNode',
                  this.$refs.CatTree
                )
                const firstNode = this.$refs.CatTree.catTreeObj.getNodeByParam(
                  'id',
                  this.defaultCats[0].id
                )
                if (
                  firstNode &&
                  firstNode.getPath()[0].id != this.defaultCats[0].cid_path[0]
                ) {
                  return
                }
                //console.log(firstNode, firstNode.getPath())
                this.defaultCats.forEach((item) => {
                  const node = this.$refs.CatTree.catTreeObj.getNodeByParam(
                    'id',
                    item.id
                  )
                  if (node) {
                    item.path = this.$refs.CatTree.computeNodePath(node).path
                  }
                })
                this.catName = this.defaultCats
                  .map((item) => item.path.join('>'))
                  .join('、')
                clearInterval(interval)
              } else if (this.defaultCats.length == 0) {
                this.catName = ''
                clearInterval(interval)
              }
            }, 100)
            this.showCatTree = true
            this.filterObj.item_ids = res.result.data.item_ids
            this.filterObj.attrv_and = []
            this.filterObj.attrv_or = []
            this.filterObj.attr_logic = res.result.data.attr_logic
            this.filterObj.brand = res.result.data.brand
            this.filterObj.brand_id = res.result.data.brand_id
            this.filterObj.shop = res.result.data.shop_name
            this.filterObj.shop_id = res.result.data.shop_id
            if (res.result.data.comment_url_id) {
              this.filterObj.comment_url_id = res.result.data.comment_url_id
            }
            this.filterObj.rule = res.result.data.rule
            this.filterObj.rule_id = res.result.data.rule_id
            this.filterObj.rule_group_id = res.result.data.rule_group_id
            this.filterObj.title_relation = res.result.data.title_relation
            this.filterObj.topAttrList = res.result.data.topAttrList
            this.filterObj.online_time = res.result.data.online_time
            this.filterObj.filing_date = res.result.data.filing_date
            this.filterObj.filing_name = res.result.data.filing_name
            if (
              res.result.data.attr_filters.length > 0 &&
              res.result.data.attr_filters[0].name == '-'
            ) {
              let arr = res.result.data.attr_filters
              arr.shift()
              this.filterObj.attr_filters = arr
            } else {
              this.filterObj.attr_filters = res.result.data.attr_filters
            }
            if (this.filterObj.attr_filters.length > 0) {
              let arr = this.filterObj.attr_filters.map(function (item) {
                var obj = {
                  name: item.name,
                  value: item.value,
                  range: item.range,
                  popular_attr_prompt: item.popular_attr_prompt,
                  attr_type: item.attr_type,
                }
                if (item['备案编号']) {
                  obj['备案编号'] = item['备案编号']
                }
                return obj
              })
              //因为当tab栏的filterObj.attr_filters删除了数据后会重新调用接口，赋值新的id，为了保证页面效果的展示所以加上以下判断，如果返回数据和现有的数据一致就不用重新赋值，避免渲染页面
              if (!_.isEqual(arr, self.filterObj.attr_filters)) {
                this.filterObj.attr_filters = _.map(
                  self.filterObj.attr_filters,
                  function (item) {
                    let obj = {
                      id: _.uniqueId(),
                      name: item.name,
                      value: item.value,
                      range: item.range,
                      popular_attr_prompt: item.popular_attr_prompt,
                      attr_type: item.attr_type,
                    }
                    if (item['备案编号']) {
                      obj['备案编号'] = item['备案编号']
                    }
                    return obj
                  }
                )
              }
              // 如果filterObj.attr_filters的对象中没有id,需要赋值一个id,方便后续删减筛选属性操作
              if (
                self.filterObj.attr_filters.filter((item) => !item.id).length >
                0
              ) {
                this.filterObj.attr_filters = _.map(
                  self.filterObj.attr_filters,
                  function (item) {
                    let obj = {
                      id: _.uniqueId(),
                      name: item.name,
                      value: item.value,
                      range: item.range,
                      popular_attr_prompt: item.popular_attr_prompt,
                      attr_type: item.attr_type,
                    }
                    if (item['备案编号']) {
                      obj['备案编号'] = item['备案编号']
                    }
                    return obj
                  }
                )
              }
            }
            if (this.filterObj.attr_filters.length > 0) {
              let filters = this.filterObj.attr_filters
              if (this.isOversea) {
                filters.forEach((item) => {
                  item.name_source = item.name
                  var name = (_.find(
                    this.filterObj.topAttrList || [],
                    (attrs) => {
                      return _.includes(attrs, item.name)
                    }
                  ) || [])[1]
                  let name_show = '',
                    name_tip = ''
                  if (this.languageMode) {
                    if (this.languageMode == 'system') {
                      name_show = name
                      name_tip = `${item.name_source || ''}：${item.value}`
                    } else if (this.languageMode == 'source') {
                      name_show = item.name_source || ''
                      name_tip = `${name || ''}：${item.value}`
                    } else {
                      name_show = `${name}/${item.name_source}`
                    }
                  }
                  if (
                    !this.$store.state.user.isOversea &&
                    localStorage.getItem('language') == 'en'
                  ) {
                    if (this.fenxiLanguageMode == 'zh') {
                      name_show = name
                    } else if (this.fenxiLanguageMode == 'en') {
                      name_show = item.name_source
                    } else {
                      name_show = `${name}/${item.name_source}`
                    }
                  }
                  item['name_ori'] = name
                  item['name_show'] = name_show
                  item['name_tip'] = name_tip
                })
              }
              let arr = _.filter(filters, function (item) {
                return !_.includes(['省', '市', '区县'], item.name)
              })
              if (arr.length > 0 && arr[0].name != '-') {
                //表示数据为sku级
                this.isSku_Filter = true
              } else {
                this.isSku_Filter = false
              }
            } else {
              this.isSku_Filter = false
            }
            /**
             * ! 通过两个属性判断是否需要对优惠券版本做控制
             * ! 满足其中一个就限制优惠券版本选择
             * ! 1. 是否有属性规则
             * ! 2. title_filter_mode是否含有sku
             */
            const { is_attr_rule, title_filter_mode } = res.result.data
            if (is_attr_rule) {
              this.isSku_Custom = true
            } else if (
              title_filter_mode &&
              title_filter_mode.indexOf('sku') > -1
            ) {
              this.isSku_Custom = true
            } else {
              this.isSku_Custom = false
            }
            this.filterObj.topAttrCustomobj = res.result.data.topAttrCustomobj
            if (this.isOversea) {
              _.each(this.filterObj.topAttrList || [], (attr) => {
                if ((attr[3] || attr[2]) && this.isOversea) {
                  this.filterObj.topAttrCustomobj[attr[3] || attr[2]] = attr[0]
                }
              })
            }
            this.filterObj.startMonth = res.result.data.start
            this.filterObj.endMonth = res.result.data.end
            if (this.$store.state.user.startMonth) {
              this.dateTime = [
                this.$store.state.user.startMonth,
                this.$store.state.user.endMonth,
              ]
            } else {
              this.dateTime = [res.result.data.start, res.result.data.end]
            }
            this.timeCouponChange()
            this.time = [this.dateTime]
            if (
              res.result.data.avg_price &&
              res.result.data.avg_price[0] == '' &&
              res.result.data.avg_price[1] == ''
            ) {
              this.filterObj.avg_price = []
            } else {
              this.filterObj.avg_price = res.result.data.avg_price
            }
            if (
              res.result.data.sold &&
              res.result.data.sold[0] == '' &&
              res.result.data.sold[1] == ''
            ) {
              this.filterObj.sold = []
            } else {
              this.filterObj.sold = res.result.data.sold
            }
            if (
              res.result.data.sales &&
              res.result.data.sales[0] == '' &&
              res.result.data.sales[1] == ''
            ) {
              this.filterObj.sales = []
            } else {
              this.filterObj.sales = res.result.data.sales
            }
            this.filterObj.title = res.result.data.title
            if (res.result.data.title_trans) {
              this.filterObj.title_trans = res.result.data.title_trans
            } else {
              delete this.filterObj.title_trans
            }
            this.filterObj.filing_name = res.result.data.filing_name
            this.filterObj.sub_name = res.result.data.sub_name
            this.filterObj.top = res.result.data.allattr

            this.sub_name = res.result.data.sub_name
            sessionStorage.setItem(
              'filterParams',
              JSON.stringify(res.result.data)
            )
            this.getParamsActivedTab()
            this.$store
              .dispatch('user/changeFilterParams', res.result.data)
              .then(() => {
                this.$eventBus.$emit('showDetail', true)
                // setTimeout(() => {
                //   this.downloadBtnShow(res.result.data)
                // }, 1000)
              })
            // this.$store.dispatch(
            //   'user/changeMoneySymbol',
            //   getMoneySymbol(this.plat)
            // )
          }
          this.loading = false
        })
        .catch((e) => {
          console.log(e)
          this.loading = false
          this.$message({
            message: e.backMsg,
            type: 'warning',
          })
        })
        .finally(() => {
          this.loading = false
          getMarked({
            url_id: this.urlId,
            site_lang: this.$i18n.locale,
          }).then((res) => {
            if (res.status == 'ok') {
              const data = res.result.data
              if (data.findIndex((item) => item.url_id == this.urlId) > -1) {
                this.isMarked = true
              } else {
                this.isMarked = false
              }
            }
          })
        })
    },
    /**
     * url_id 解析后确定当前选中的tab. 统一处理
     */
    getParamsActivedTab() {
      // 通用场景
      const ActivedTab = this.$store.state.user.ActivedTab
      if (ActivedTab == 'PriceDis' || ActivedTab == 'BrandPriceDis') {
        this.$store.dispatch('user/changeActivedTab', 'Summary')
        localStorage.setItem('passActivedTab', 'Summary')
      }
      if (
        this.$route.params &&
        (this.$route.params.custom_rule || this.$route.params.pivot_config)
      ) {
        return
      }
      // else {
      //   this.$store.dispatch('user/changeActivedTab', 'Summary')
      //   localStorage.setItem('passActivedTab', 'Summary')
      // }
      if (ActivedTab.indexOf('CustomTable') > -1) {
        const rule_id = ActivedTab.split('-')[1]
        if (this.module_info.indexOf(rule_id) == -1) {
          this.$store.dispatch('user/changeActivedTab', 'Summary')
          localStorage.setItem('passActivedTab', 'Summary')
        }
        if (!this.$store.state.user.customModule.id) {
          this.$store.dispatch('user/changeActivedTab', 'Summary')
          localStorage.setItem('passActivedTab', 'Summary')
        }
        return
      }
      // 如果没有子类目, 自动跳转到Summary页面
      if (
        this.tabCats.length == 1 &&
        !JSON.parse(this.tabCats[0].is_parent) &&
        ActivedTab == 'Catlist'
      ) {
        this.$store.dispatch('user/changeActivedTab', 'Summary')
        localStorage.setItem('passActivedTab', 'Summary')
        return
      }
      if (!this.isOversea) {
        // fenxiplus 场景
      } else if (this.isOversea) {
        // oversea 场景
        if (this.tabCats.length == 0) {
          // oversea 且没选择类目只能选择 '类目分析' tab
          this.$store.dispatch('user/changeActivedTab', 'Catlist')
          localStorage.setItem('passActivedTab', 'Catlist')
        } else {
          this.$store.dispatch('user/changeActivedTab', 'Catlist')
          localStorage.setItem('passActivedTab', 'Catlist')
          setTimeout(() => {
            this.$store.dispatch('user/changeActivedTab', 'Summary')
            localStorage.setItem('passActivedTab', 'Summary')
            this.$forceUpdate()
          }, 100)
        }
      }
    },
    getPlatList() {
      let self = this
      var _plat = this.checkPlatFenxi(this.plat)
      var platList = null
      if (_.includes(_plat, 'taobao') || _.includes(_plat, 'tmall')) {
        platList = [
          {
            id: 'cshop,tmall',
          },
          {
            id: 'cshop',
          },
          {
            id: 'global',
          },
          {
            id: 'tmall',
          },
          {
            id: 'tmallhk',
          },
          {
            id: 'tmallhk_gfzy',
          },
          {
            id: 'notmallhk',
          },
          {
            id: 'chaoshi',
          },
          {
            id: 'nochaoshi',
          },
          {
            id: 'tmqjd',
          },
          {
            id: 'tmzyzmd',
          },
        ]
      } else if (_plat == 'jd') {
        platList = [
          {
            id: 'jd',
          },
          {
            id: 'jd_only_self',
          },
          {
            id: 'jd_exclude_self',
          },
          {
            id: 'jd_hk',
          },
          {
            id: 'jd_home_self',
          },
          {
            id: 'jd_home_pop',
          },
          {
            id: 'jd_not_home_self',
          },
          {
            id: 'jd_not_home_pop',
          },
        ]
      }
      if (platList) {
        self.platList = _.filter(platList, function (item) {
          item.name = self.platNameMap[item.id]
          return self.plat !== item.id
        })
      }
    },
    // 加平台需修改
    checkPlatFenxi(plat) {
      switch (plat) {
        case 'global':
        case 'cshop':
          return 'taobao'
        case 'tmall':
        case 'chaoshi':
        case 'nochaoshi':
        case 'tmallhk':
        case 'notmallhk':
        case 'tmzyzmd':
        case 'tmqjd':
        case 'tmallhk_gfzy':
        case 'all':
          return 'tmall'

        case 'jd':
        case 'jd_only_self':
        case 'jd_exclude_self':
        case 'jd_hk':
        case 'jd_home_self':
        case 'jd_home_pop':
        case 'jd_not_home_self':
        case 'jd_not_home_pop':
          return 'jd'
        case 'amazon_us':
        case 'amazon_de':
        case 'amazon_es':
        case 'amazon_fr':
        case 'amazon_it':
        case 'amazon_uk':
        case 'amazon_jp':
        case 'amazon_br':
        case 'amazon_mx':
          return 'amazon'
        case 'shopee_br':
        case 'shopee_cl':
        case 'shopee_co':
        case 'shopee_es':
        case 'shopee_fr':
        case 'shopee_id':
        case 'shopee_in':
        case 'shopee_mx':
        case 'shopee_my':
        case 'shopee_ph':
        case 'shopee_pl':
        case 'shopee_sg':
        case 'shopee_th':
        case 'shopee_tw':
        case 'shopee_vn':
          return 'shopee'
        case 'lazada_my':
        case 'lazada_sg':
        case 'lazada_th':
        case 'lazada_ph':
        case 'lazada_id':
        case 'lazada_vn':
          return 'lazada'
        case 'tiktok_gb':
        case 'tiktok_id':
        case 'tiktok_my':
        case 'tiktok_ph':
        case 'tiktok_sg':
        case 'tiktok_th':
        case 'tiktok_us':
        case 'tiktok_vn':
          return 'tiktok'
        case 'ozon_ru':
          return 'ozon'
        default:
          return plat
      }
    },
    buildVerList() {
      let self = this
      var verList = this.userVersionSelected
      if (
        this.isMoojingUser ||
        (this.loginInfo.username &&
          this.loginInfo.username.endsWith('@moojing.com'))
      ) {
        this.isShowUserCoupon = true
      } else {
        if (verList.length == 0) {
          this.isShowUserCoupon = false
          this.version = ''
          this.$store.dispatch('user/changeVersion', this.version)
          localStorage.setItem('version', this.version)
        } else {
          let arr = []
          _.map(verList, function (v) {
            let item = _.find(self.couponLists, function (o) {
              return o.value == v
            })
            if (item) {
              arr.push(item)
            }
          })
          // 用户如果没设置'不减优惠卷权限',就自动增加进去
          // if (arr[0].name !== this.$t('mainHeader.no_discount')) {
          //   arr.unshift({ name: this.$t('mainHeader.no_discount'), value: '' })
          // }
          this.verMap = arr
          this.couponList = arr
          const storeVersion = localStorage.getItem('version')
          if (
            storeVersion &&
            arr.findIndex((item) => item.value == storeVersion) > -1
          ) {
            this.version = storeVersion
          } else {
            this.version = arr[0].value
          }
          this.$store.dispatch('user/changeVersion', this.version)
          localStorage.setItem('version', this.version)
          this.isShowUserCoupon = true
        }
      }
    },
    handleRemove(key) {},
    search() {
      this.$message.warning(this.$t('mainHead.rules_message_search'))
    },
    handleTabClick(tab, event) {
      if (this.isOversea && this.tabCats.length == 0) {
        this.$message.warning(this.$t('mainHead.only_cat_tip'))
        return
      }
      if (tab.name == 'GeneratePPT') {
        this.$refs.GeneratePPT.generate()
        return
      }
      let item = this.editableTabs.find((item) => {
        return item.name == tab.name
      })
      item.plat = this.plat

      if (tab.name.indexOf('CustomTable') > -1) {
        this.$store.dispatch('user/changeCustomModule', item)
      }
      this.$store.dispatch('user/changeActivedTab', tab.name)
      localStorage.setItem('passActivedTab', tab.name)
    },
    handleBrandNameClose(e) {
      if (this.filterObj.brand) {
        this.filterObj.brand_id = ''
        this.filterObj.brand = ''
        this.$store.dispatch('user/changeBrandName', '')
        this.$store.dispatch('user/changeBrandId', '')
        this.getUrlId()
      }
    },
    handleRuleClose() {
      if (this.filterObj.rule) {
        this.filterObj.rule = ''
        this.form.rule_id = ''
        this.form.rule_group_id = ''
        this.filterObj.rule_id = ''
        this.filterObj.rule_group_id = ''
        this.getUrlId()
      }
    },
    handleCommentClose() {
      this.filterObj.comment_url_id = ''
      this.getUrlId()
    },
    handleShopNameClose(e) {
      if (this.filterObj.shop) {
        this.filterObj.shop_id = ''
        this.filterObj.shop = ''
        this.$store.dispatch('user/changeShopName', '')
        this.$store.dispatch('user/changeShopId', '')
        this.getUrlId()
      }
    },
    handleAvgPriceClose(e) {
      this.filterObj.avg_price = ['', '']
      this.form.avg_price = ['', '']
      this.getUrlId()
    },
    handleSoldClose(e) {
      this.filterObj.sold = []
      this.form.sold = []
      this.getUrlId()
    },
    handleSalesClose(e) {
      this.filterObj.sales = []
      this.form.sales = []
      this.getUrlId()
    },
    handleTitleClose(e) {
      this.filterObj.title = ''
      this.form.title = ''
      this.getUrlId()
    },
    handleItemsIdClose() {
      this.filterObj.item_ids = ''
      this.getUrlId()
    },
    handleFilingNameClose() {
      this.filterObj.filing_name = ''
      this.form.filing_name = ''
      this.getUrlId()
    },
    handleOnlineTimeClose() {
      this.filterObj.online_time = ['', '']
      this.getUrlId()
    },
    handleFilingDateClose() {
      this.filterObj.filing_date = ['', '']
      this.getUrlId()
    },
    handleAttrClose(id) {
      let self = this
      let index = this.filterObj.attr_filters.findIndex(
        (item) => item.id === id
      )
      this.filterObj.attr_filters.splice(index, 1)
      let arr = this.filterObj.attr_filters.map(function (item) {
        var obj = {}
        if (item.name) {
          if (item.popular_attr_prompt == 'checkbox') {
            obj.key = item.name
            obj.value = item.value
            obj.range = item.range ? item.range : null
            obj.popular_attr_prompt = item.popular_attr_prompt
            obj.attr_type = item.attr_type
          } else {
            obj[item.name] = item.value
            obj['attr_type'] = item.attr_type
          }
        } else {
          obj = null
        }
        return obj
      })
      if (this.filterObj.attr_logic == 'and') {
        this.form.attrv_and = arr
        this.form.attrv_or = []
      } else if (this.filterObj.attr_logic == 'or') {
        this.form.attrv_and = []
        this.form.attrv_or = arr
      }
      if (this.filterObj.attr_filters.length == 0) {
        this.filterObj.topAttrList = []
      }
      this.getUrlId()
    },
    handlePlatChange() {
      this.$store.dispatch('user/changeplats', this.checkList)
    },
    showFilter() {
      this.isShowFilter = !this.isShowFilter
      this.$store.dispatch('user/changeShowFilter', this.isShowFilter)
    },
    addAtention() {
      if (!this.isMarked) {
        let params = {
          url_id: this.urlId,
          site_lang: this.$i18n.locale,
        }
        putMarked(params).then((res) => {
          if (res.result.msg == 'ok') {
            this.isMarked = true
          } else {
            this.$message.warning(res.result.msg)
          }
        })
      } else {
        let params = {
          url_id: this.urlId,
          site_lang: this.$i18n.locale,
        }
        deleteMarked(params).then((res) => {
          if (res.result.msg == 'ok') {
            this.isMarked = false
          } else {
            this.$message.warning(res.result.msg)
          }
        })
      }
    },
    jdVersionChange(val) {
      // this.$store.dispatch("user/changeJdVersion",val)
      localStorage.setItem('jdVersion', JSON.stringify(val))
      this.$eventBus.$emit('jdVersionChange', val)
    },
    douyinVersionChange(val) {
      localStorage.setItem('douyinVersion', JSON.stringify(val))
      this.$eventBus.$emit('jdVersionChange', val)
    },
    // downloadBtnShow(params) {
    //   if (!this.$refs.CatTree) return
    //   const loginInfo = this.$store.state.user.loginInfo
    //   if (loginInfo.cat_list !== 'all' && loginInfo.show_all_cats == true) {
    //     // if (true) {
    //     for (let i = 0; i < params.cats.length; i++) {
    //       const item = params.cats[i]
    //       // const cidPath = item.cid_path.flat()
    //       // for (let j = 0; j < cidPath.length; j++) {
    //       const cid = item.id
    //       const onlyShow = this.$refs.CatTree.hasPermission(cid)[1]
    //       if (onlyShow) {
    //         this.$store.dispatch('user/changeShowDownloadBtn', false)
    //         return
    //       }
    //       // }
    //     }
    //     this.$store.dispatch('user/changeShowDownloadBtn', true)
    //   }
    // },
  },
  watch: {
    languageMode(newVal) {
      let filters = this.filterObj.attr_filters
      filters.forEach((item) => {
        item.name_source = item.name_source || item.name
        var name =
          item.name_ori ||
          _.find(this.filterObj.topAttrList || [], (attrs) => {
            return _.includes(attrs, item.name)
          })[1]
        let name_show = '',
          name_tip = ''
        if (newVal) {
          if (newVal == 'system') {
            name_show = name
            name_tip = `${item.name_source || ''}：${item.value}`
          } else if (newVal == 'source') {
            name_show = item.name_source || ''
            name_tip = `${name || ''}：${item.value}`
          } else {
            name_show = `${name}/${item.name_source}`
          }
        }
        item['name_show'] = name_show
        item['name_tip'] = name_tip
      })
      this.filterObj.attr_filters = [...filters]
      let plat = 'cache_cat_oversea_' + this.plat
      let childrenList = window[plat].result.childrenList
      let arr = []
      this.cats.forEach((item) => {
        arr.push({
          path: [this.getCatName(childrenList[item.id])],
        })
      })
      this.catName = arr.map((item) => item.path.join('>')).join('、')
    },
    fenxiLanguageMode(newVal) {
      let filters = this.filterObj.attr_filters
      filters.forEach((item) => {
        item.name_source = item.name_source || item.name
        var name =
          item.name_ori ||
          _.find(this.filterObj.topAttrList || [], (attrs) => {
            return _.includes(attrs, item.name)
          })[1]
        let name_show = '',
          name_tip = ''
        if (newVal) {
          if (newVal == 'zh') {
            name_show = name
          } else if (newVal == 'en') {
            name_show = item.name_source
          } else {
            name_show = `${name}/${item.name_source}`
          }
        }
        item['name_show'] = name_show
        item['name_tip'] = name_tip
      })
      this.filterObj.attr_filters = [...filters]
      let childrenList = this.$refs.CatTree.catsData.result.childrenList
      let arr = []
      this.cats.forEach((item) => {
        arr.push({
          path: [this.getCatName(childrenList[item.id])],
        })
      })
      this.catName = arr.map((item) => item.path.join('>')).join('、')
    },
    tabParam(newVal, oldVal) {
      if (this.isOversea) {
        //oversea支持类目为空的处理所以不需要判断newVal.cats.length > 0
        if (Object.keys(newVal.loginInfo).length > 0) {
          this.editableTabs = []
          this.setTab()
        }
      } else {
        if (
          newVal.cats.length > 0 &&
          Object.keys(newVal.loginInfo).length > 0
        ) {
          this.editableTabs = []
          this.setTab()
        }
      }
    },
    cats(newVal, oldVal) {
      if (newVal[0] && newVal[0].custom) {
        this.cid = {
          custom: [newVal[0]._id],
        }
        this.sub_name = newVal[0].sub_name
      } else {
        const cids = newVal.map((item) => item.id)
        this.cid = {
          not_custom: cids,
        }
        this.sub_name = ''
      }
      this.getTimeList()
    },
    sub_name: {
      handler(newVal, oldVal) {
        if (this.plat == 'douyin') {
          this.isShowCoupon = false
          return
        }
        let platArr = this.plat.split(',')
        if (platArr.indexOf('taobao') > -1 || platArr.indexOf('tmall') > -1) {
          if (newVal == 'custom_102') {
            this.isShowCoupon = false
          } else {
            this.isShowCoupon = true
          }
        }
        // if (newVal.indexOf('custom') > -1) {
        //   this.isShowJdVersion = false
        // }
        if (!this.isFilingCat) {
          let passActivedTabValue = localStorage.getItem('passActivedTab')
          if (
            passActivedTabValue == 'filing_product_analyze' ||
            passActivedTabValue == 'filing_effect_analyze' ||
            passActivedTabValue == 'filing_ingredient_analyze' ||
            passActivedTabValue == 'filing_production_company_analyze'
          ) {
            this.$store.dispatch('user/changeActivedTab', 'Summary')
            localStorage.setItem('passActivedTab', 'Summary')
          }
        }
      },
    },
    plat: {
      handler(newVal, oldVal) {
        console.log(newVal)
        if (isMultiPlat(newVal)) {
          let platArr = newVal.split(',')
          platArr = platArr.map((item) => {
            const plat = this.checkPlatFenxi(item)
            return plat
          })
          if (platArr.indexOf('taobao') > -1 || platArr.indexOf('tmall') > -1) {
            this.isShowCoupon = true
          } else {
            this.isShowCoupon = false
            if (platArr.indexOf('jd') > -1) {
              this.isShowJdVersion = true
            }
          }
        } else {
          if (newVal.indexOf('jd') == 0) {
            this.isShowCoupon = false
            this.isShowJdVersion = true
          } else if (newVal.indexOf('douyin') > -1 || this.isOversea) {
            this.isShowCoupon = false
            this.isShowJdVersion = false
          } else {
            this.isShowCoupon = true
            this.isShowJdVersion = false
          }
        }
      },
    },
    '$store.state.user.ActivedTab': {
      handler(newVal, oldVal) {
        // let botParam = _.cloneDeep(this.$store.state.user.chatParams)
        // if (apiKey[newVal]) {
        //   botParam.tab = apiKey[newVal].tab
        // } else if (newVal.startsWith('CustomTable-custom_analyze_')) {
        //   botParam.tab = _.find(this.tabs, function (o) {
        //     return o.name == newVal
        //   }).title
        // }
        // botParam.page_info = {}
        // this.$store.dispatch('user/changeChatParams', botParam)
        if (newVal == 'Attrlist') {
          this.isSku_ActivedTab = true
        } else {
          this.isSku_ActivedTab = false
        }
      },
    },
    isSku: {
      handler(newVal, oldVal) {
        if (newVal) {
          if (this.verMap.length > 0) {
            let arr = []
            let couponListSku = this.couponListSku
            _.map(this.verMap, function (v) {
              let item = _.find(couponListSku, function (o) {
                return o.value == v.value
              })
              if (item) {
                arr.push(item)
              }
            })
            this.couponList = arr
          } else {
            this.couponList = this.couponListSku
          }
          this.$forceUpdate()
        } else {
          if (this.verMap.length > 0) {
            let arr = []
            let couponLists = this.couponLists
            _.map(this.verMap, function (v) {
              let item = _.find(couponLists, function (o) {
                return o.value == v.value
              })
              if (item) {
                arr.push(item)
              }
            })
            this.couponList = arr
          } else {
            this.couponList = this.couponLists
          }
          this.$forceUpdate()
        }
        if (
          this.couponLists.findIndex((item) => item.value == this.version) == -1
        ) {
          this.version = this.couponList[0].value
        }
        this.$store.dispatch('user/changeVersion', this.version)
        localStorage.setItem('version', this.version)
        this.timeCouponChange()
      },
    },
    '$store.state.user.startMonth': {
      handler(newVal, oldVal) {
        this.startMonth = newVal
        this.dateTime = [newVal, this.dateTime[1]]
      },
    },
    '$store.state.user.endMonth': {
      handler(newVal, oldVal) {
        this.endMonth = newVal
        this.dateTime = [this.dateTime[0], newVal]
      },
    },
    '$store.state.user.shopId': {
      handler(newVal, oldVal) {
        this.filterObj.shop_id = newVal
        this.getUrlId()
      },
    },
    '$store.state.user.shopName': {
      handler(newVal, oldVal) {
        this.filterObj.shop = newVal
        let arr1 = this.form.attrv_and
          .filter((item) => item.popular_attr_prompt !== 'checkbox')
          .map((item) => {
            const temp = {
              attr_type: item.attr_type,
              attr: Object.keys(item).filter(
                (ele) =>
                  ele != 'attr_type' &&
                  ele != 'popular_attr_prompt' &&
                  !_.includes(['attr_value_show', 'attr_value_source'], ele)
              )[0],
            }
            return temp
          })
        let arr2 = this.form.attrv_or
          .filter((item) => item.popular_attr_prompt !== 'checkbox')
          .map((item) => {
            const temp = {
              attr_type: item.attr_type,
              attr: Object.keys(item).filter(
                (ele) =>
                  ele != 'attr_type' &&
                  ele != 'popular_attr_prompt' &&
                  !_.includes(['attr_value_show', 'attr_value_source'], ele)
              )[0],
            }
            return temp
          })
        this.form.keys = arr1.concat(arr2)
        this.getUrlId()
      },
    },
    '$store.state.user.brandId': {
      handler(newVal, oldVal) {
        this.filterObj.brand_id = newVal
        this.getUrlId()
      },
    },
    '$store.state.user.brandName': {
      handler(newVal, oldVal) {
        this.filterObj.brand = newVal
        let arr1 = this.form.attrv_and
          .filter((item) => item.popular_attr_prompt !== 'checkbox')
          .map((item) => {
            const temp = {
              attr_type: item.attr_type,
              attr: Object.keys(item).filter(
                (ele) =>
                  ele != 'attr_type' &&
                  ele != 'popular_attr_prompt' &&
                  !_.includes(['attr_value_show', 'attr_value_source'], ele)
              )[0],
            }
            return temp
          })
        let arr2 = this.form.attrv_or
          .filter((item) => item.popular_attr_prompt !== 'checkbox')
          .map((item) => {
            const temp = {
              attr_type: item.attr_type,
              attr: Object.keys(item).filter(
                (ele) =>
                  ele != 'attr_type' &&
                  ele != 'popular_attr_prompt' &&
                  !_.includes(['attr_value_show', 'attr_value_source'], ele)
              )[0],
            }
            return temp
          })
        this.form.keys = arr1.concat(arr2)
        this.getUrlId()
      },
    },
    '$store.state.user.attrvAnd': {
      handler(newVal, oldVal) {
        if (newVal[newVal.length - 1].attr_type !== 'filing') {
          this.form.attr_vague = false
        }

        this.filterObj.attrv_and = newVal
        let topAttrListParams = JSON.parse(
          sessionStorage.getItem('topAttrList')
        )
        this.filterObj.topAttrList = topAttrListParams ? topAttrListParams : []
        this.form.keys = newVal
          .filter((item) => item.popular_attr_prompt !== 'checkbox')
          .map((item) => {
            const temp = {
              attr_type: item.attr_type,
              attr: Object.keys(item).filter(
                (ele) =>
                  ele != 'attr_type' &&
                  ele != 'popular_attr_prompt' &&
                  !_.includes(['attr_value_show', 'attr_value_source'], ele)
              )[0],
            }
            return temp
          })
        this.getUrlId()
      },
    },
    '$store.state.user.customRule': {
      handler(newVal, oldVal) {
        this.form.rule_id = newVal.rule_id
        this.form.rule_group_id = newVal.rule_group_id
        this.getUrlIds().then((res) => {
          this.$store.dispatch('user/changeActivedTab', 'Summary')
        })
      },
    },
    '$store.state.user.urlId': {
      handler(newVal, oldVal) {
        this.form.keys = []
        this.urlId = newVal
        this.isShowFilter = false
        this.$eventBus.$emit('showDetail', false)
        this.getParams()
      },
    },
    '$store.state.user.loginInfo': {
      handler(newVal, oldVal) {
        this.isMoojingUser = newVal.username.endsWith('@moojing.com')
        this.loginInfo = newVal
        this.userVersionSelected = newVal.version_selected
        this.buildVerList()
        this.getModuleInfo()
        // this.$set(this, 'loginInfo', newVal)
      },
    },
    '$store.state.user.isSkuGrade': {
      handler(newVal, oldVal) {
        this.isSku_Grade = newVal
      },
      immediate: true,
      deep: true,
    },
    '$store.state.user.ts': {
      handler(newVal, oldVal) {
        this.ts = newVal
        this.getTimeList()
      },
      immediate: true,
      deep: true,
    },
    '$store.state.user.filingName': {
      handler(newVal) {
        this.filterObj.filing_name = newVal
        this.getUrlId()
      },
    },
  },
}
</script>

<style scoped lang="less">
.header {
  .header-one {
    .brand {
      flex: 1;
      flex-wrap: nowrap !important;
      overflow-x: auto;
    }
  }
  .myAtention {
    min-width: 110px;
    cursor: pointer;
  }
}
.catName {
  background: #ff6a5b;
  color: white;
}
.brandName {
  background: #29b36e;
}
.avgPrice {
  background: #427ff0;
}
.sold {
  background: #9338e9;
}
.sales {
  background: #74d150;
}
.title {
  background: #5bc0de;
}
.item-id {
  background: #cc29b3;
}
.attrName {
  background: #7baaf1;
}
.online_time {
  background: #f19f7b;
}
.filing_date {
  background: #f19f7b;
}
.filing_name {
  background: #58a7e6;
}
.filterIcon {
  height: 28px;
  width: 115px;
  text-align: center;
  line-height: 28px;
  border-radius: 2px;
  display: flex;
  cursor: pointer;
  i {
    color: #333;
  }
}
.tagName {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  background-color: rgba(72, 219, 192, 0.3);
  border: 1px solid #ffe5cc;
  height: 26px;
  line-height: 26px;
  margin-left: 10px;
  margin-top: 10px;
}
// .activeFilter {
//   background: #3a63f333;
//   i {
//     color: #3a63f3;
//   }
// }
.timePick {
  /deep/ .el-date-editor .el-range-separator {
    width: 7%;
  }
}
.custom-star {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-family: 'iconfont';
}
.custom-star::before {
  font-size: 16px;
  content: '\e625';
  color: #e89e42;
}
.red.custom-star::before {
  font-size: 16px;
  content: '\e625';
  color: red;
}
// .intro-btn {
//   min-width: 80px;
//   height: 28px;
//   font-size: 14px;
//   line-height: 25px;
//   color: @fenxiPrimaryColor;
//   background-color: transparent;
//   border-color: transparent;
//   border-radius: 3px;
//   padding: 0px 0px;
// }
// .intro-btn:focus,
// .intro-btn:hover,
// .intro-btn:active {
//   color: @fenxiPrimaryColor;
//   border-color: transparent;
//   background-color: transparent;
// }
.notification {
  position: relative;
  display: inline-block;

  .note-dot {
    position: absolute;
    top: 4px;
    right: -10px;
    background-color: red;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}
</style>
<style lang="less">
.time-pick {
  .el-input--mini .el-input__inner {
    background: transparent;
  }
}
</style>
